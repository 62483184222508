import React, { useState, useEffect, useRef } from "react";
import styles from "./Terminal.module.css";

interface Entry {
  line: string;
  output: string;
}
// utility functions
const formatDateTime = () => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const year = now.getFullYear();

  return {
    time: `${hours}:${minutes}:${seconds}`,
    date: `${day}/${month}/${year}`,
  };
};

const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const Terminal: React.FC = () => {
  const [commandHistory, setCommandHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [command, setCommand] = useState("");
  const [entries, setEntries] = useState<Entry[]>([]);
  const [titleTime, setTitleTime] = useState(formatDateTime().time);
  const terminalRef = useRef<HTMLDivElement>(null);

  // Add a useEffect hook to update the title time every second:
  useEffect(() => {
    const timer = setInterval(() => {
      setTitleTime(formatDateTime().time);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Functions
  function tripWorthinessCalculator(oneway: number, stay: number) {
    const totalTravelTime = oneway * 2;
    const totalTime = totalTravelTime + stay;
    const rentability = ((stay - totalTravelTime) / stay) * 100;

    const timeFormat = (mins: number) => {
      const hours = Math.floor(mins / 60);
      const minutes = mins % 60;
      return hours > 0
        ? `${hours}h${minutes > 0 ? `${minutes}m` : ""}`
        : `${minutes}m`;
    };

    const result = `Your travelling for ${timeFormat(
      totalTravelTime
    )} to stay ${timeFormat(
      stay
    )} in your destination. You are spending ${timeFormat(
      totalTime
    )} in total. Your time rentability is ${rentability.toFixed(2)}%.`;

    return result;
  }

  const fetchWeather = async () => {
    try {
      const response = await fetch("https://wttr.in?format=%C+%t+%w");
      if (response.ok) {
        const data = await response.text();
        return data;
      }
      throw new Error("Error fetching weather data");
    } catch (error) {
      console.error(error);
      return "Error fetching weather data";
    }
  };

  const handleArrowKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp") {
      if (historyIndex < commandHistory.length - 1) {
        setHistoryIndex((prevIndex) => prevIndex + 1);
        setCommand(commandHistory[commandHistory.length - historyIndex - 2]);
      }
    } else if (e.key === "ArrowDown") {
      if (historyIndex > -1) {
        setHistoryIndex((prevIndex) => prevIndex - 1);
        setCommand(
          historyIndex === 0
            ? ""
            : commandHistory[commandHistory.length - historyIndex]
        );
      }
    }
  };

  const executeCommand = async () => {
    const trimmedCommand = command.trim();
    const { time } = formatDateTime();
    const commandLine = `<span class="text-green-300">${time} </span><span class="font-bold text-green-300">guest@vm1-intel-xeon</span> <span class="text-green-300">~ </span> ${trimmedCommand}`;

    setCommandHistory((prevHistory) => [...prevHistory, trimmedCommand]);
    setHistoryIndex(-1);
    setCommand("");
    let output = "";

    // Command interpreter
    const parsedCommand = /^(\w+)((?:\s+-\w+=\d+)*)\s*$/.exec(trimmedCommand);
    if (parsedCommand) {
      const mainCommand = parsedCommand[1];
      const args: any = {};
      const argMatches: any = parsedCommand[2].matchAll(/-(\w+)=(\d+)/g);

      for (const match of argMatches) {
        args[match[1]] = parseInt(match[2], 10);
      }

      switch (mainCommand) {
        case "trip":
          if (args["drive"] !== undefined && args["stay"] !== undefined) {
            output = tripWorthinessCalculator(args["drive"], args["stay"]);
          } else {
            console.log(args);
            output = "Error: Missing arguments for trip command";
          }
          break;
        case "time":
          output = formatDateTime().time;
          break;
        case "services":
          output = `
            coding-cloud.com
            uml.coding-cloud.com
            blueprints.coding-cloud.com
            estimations.coding-cloud.com
            recipes.coding-cloud.com
            frontier.coding-cloud.com
                `;
          break;
        case "man":
          output = `
              man; Show the manual
              trip -drive=10 -stay=60; Calculate the rentability of a trip
              weather; Show the current weather
              services; Show the list of services from coding-cloud;
              time; Show the current time
              `;
          break;
        case "weather":
          output = await fetchWeather();
          break;
        default:
          output = `Command not found: ${trimmedCommand}`;
      }
    } else {
      output = `Invalid command format: ${trimmedCommand}. Type 'man' to see the manual.`;
    }

    setEntries([...entries, { line: commandLine, output }]);
  };

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [entries]);

  const { date } = formatDateTime();

  return (
    <div className={`h-screen flex flex-col ${styles["terminal-container"]}`}>
      <div className="flex justify-between items-center py-1 px-2 bg-gray-700 rounded-t-lg">
        <div className="flex space-x-1.5">
          <div className="w-3 h-3 bg-red-500 rounded-full"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full"></div>
        </div>
        <p className="text-xs text-gray-200">{`${date} ${titleTime}`}</p>
        <div className="text-xs text-gray-200 pr-1">coding-cloud.com</div>
      </div>
      <div
        className="flex-1 bg-gray-800 p-2 overflow-y-auto"
        id="terminal"
        ref={terminalRef}
      >
        {entries.map((entry, index) => (
          <div key={index}>
            <pre
              className={`text-green-400 font-mono text-sm ${styles.entry}`}
              dangerouslySetInnerHTML={{ __html: entry.line }}
            ></pre>
            <pre className={`text-green-400 font-mono text-sm ${styles.entry}`}>
              {entry.output}
            </pre>
          </div>
        ))}
        <div className="flex items-center">
          <span className="font-bold text-green-300">guest@vm1-intel-xeon</span>
          <span className="text-green-300">~ </span>
          <input
            type="text"
            id="input"
            className={`text-green-400 font-mono text-sm p-2 ${styles.input}`}
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                executeCommand();
              } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                handleArrowKeys(e);
              }
            }}
            autoFocus
          />
        </div>
      </div>
    </div>
  );
};

export default Terminal;
