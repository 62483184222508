import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import Terminal from "./components/Terminal";

const App: React.FC = () => {
  return (
    <div className="App">
      <Terminal />
    </div>
  );
};

export default App;
